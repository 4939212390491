import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import MapIcon from '@mui/icons-material/Map';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import QrCode2Icon from '@mui/icons-material/QrCode2';

// import NearMeIcon from '@mui/icons-material/NearMe';
// import NearMeRoundedIcon from '@mui/icons-material/NearMeRounded';


// import StorefrontIcon from '@mui/icons-material/Storefront';
import LoyaltyIcon from '@mui/icons-material/Loyalty';


// import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

// import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

// import CheckroomIcon from '@mui/icons-material/Checkroom';


// import SailingIcon from '@mui/icons-material/Sailing';


import { createTheme, ThemeProvider } from '@mui/material/styles';

const navStyle = {
  position: 'fixed',
  bottom: 0,
  width: '100%',
  zIndex: 999,
  backgroundColor: 'transparent'
};

const theme = createTheme({
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          textAlign: 'center'
        }
      }
    }
  }
});

export default function SimpleBottomNavigation(props) {
    const { value, onChange } = props;
  
    return (
      /*{<ThemeProvider theme={theme}>}*/
      <>
      <Box sx={{ position: 'fixed', left: 0, bottom: 0, width: '100%', zIndex: 10, }}>
        <BottomNavigation value={value} onChange={onChange} showLabels>
          {/* <BottomNavigationAction label="Direct" icon={<TimelineIcon />} />
          <BottomNavigationAction label="Carte" icon={<MapIcon />} /> */}
          {/* <BottomNavigationAction label="Living Cam" icon={<VideoCameraBackIcon />} /> */}
          {/* <BottomNavigationAction label="Track" icon={<LocationSearchingIcon />} /> */}
          <BottomNavigationAction label="Montre" icon={<AccessTimeIcon />} />
          <BottomNavigationAction label="QrCode" icon={<QrCode2Icon />} />
          <BottomNavigationAction label="Montre" icon={<AccessTimeIcon />} />
          <BottomNavigationAction label="Contrôle" icon={<TimelineIcon />} />

          
          {/* <BottomNavigationAction label="Shop" icon={<LoyaltyIcon />} /> */}
        </BottomNavigation>
      </Box>
      </>
      /*{</ThemeProvider>}*/
    );
  }


//      <Box sx={{ position: 'fixed', bottom: 0, width: '20%', left: '50%', transform: 'translateX(-50%)' }}>

