import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

const options_DELTA = {
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        maxTicksLimit: 12
      },
    },
    y: {
      title: {
        display: true,
        text: 'Delta Time (ms)' // Ajoutez l'unité ici
      }
    }
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Delta Time [ms]',
    },
  },
};

const Linechart = () => {
  const [data_DELTA, setData_DELTA] = useState({
    labels: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '13:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'],
    datasets: [
      // {
      //   label: 'Delta time [ms]',
      //   data: [0, 800, 600, 700, 1500, 1000, 1000, 800, 2000, -700, -2000, -2500, -3500, -600, -700, -1500, -1000, -1000, -800, -2000, -700, -2000],
      //   borderColor: 'rgb(0, 0, 255)',
      //   backgroundColor: 'rgba(0, 0, 255, 0.5)',
      //   tension: 0.3,
      //   spanGaps: true,
      //   // fill: '-1',
      // },


      {
        type: 'line',
        label: 'Limite admissible',
        data: [3000, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 3000],
        borderColor: 'rgba(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        tension: 0.3,
        spanGaps: true,
        pointRadius: 0,
        fill: 'end',
      },
      {
        type: 'line',
        label: 'Limite fabricant',
        data: [2000, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 2000],
        borderColor: 'rgba(255, 125, 0)',
        backgroundColor: 'rgba(255, 125, 0, 0.55)',
        tension: 0.3,
        spanGaps: true,
        pointRadius: 0,
        fill: '-1',
      },
      
      {
        type: 'line',
        label: 'Zone de sécurité',
        data: [2000, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 2000],
        borderColor: 'rgba(0, 255, 0)',
        backgroundColor: 'rgba(0, 255, 0, 0.5)',
        tension: 0.3,
        spanGaps: true,
        pointRadius: 3,
        borderWidth: 0,
        fill: 'origin',
      },

      {
        label: 'Delta time [ms]',
        data: [0, 800, 600, 700, 1500, 1000, 1000, 800, 2000, -700, -2000, -2500, -3500, -600, -700, -1500, -1000, -1000, -800, -2000, -700, -2000],
        borderColor: 'rgb(0, 0, 255)',
        backgroundColor: 'rgba(0, 0, 255, 0.5)',
        tension: 0.3,
        spanGaps: true,
        // fill: '-1',
      },

      {
        type: 'line',
        label: 'Zone de sécurité',
        data: [-2000, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, -2000],
        borderColor: 'rgba(0, 255, 0)',
        backgroundColor: 'rgba(0, 255, 0, 0.5)',
        tension: 0.3,
        spanGaps: true,
        pointRadius: 3,
        borderWidth: 0,
        fill: 'origin',
      },
      
      {
        type: 'line',
        label: 'Limite fabricant',
        data: [-3000, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, -3000],
        borderColor: 'rgba(255, 125, 0)',
        backgroundColor: 'rgba(255, 125, 0, 0.55)',
        tension: 0.3,
        spanGaps: true,
        pointRadius: 0,
        fill: '-1',
      },
      {
        type: 'line',
        label: 'Limite admissible',
        data: [-3000, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, -3000],
        borderColor: 'rgba(255, 0, 0)',
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        tension: 0.3,
        spanGaps: true,
        pointRadius: 0,
        fill: 'start',
      },
      
    ],
  });

  return (
    <Container fluid>
      <Row>
        <Col sm={12} lg={12}>  
          <Row>
            <div>
              <br />
              <div style={{ height: "60vh" }}>
                <Line data={data_DELTA} options={options_DELTA} />
              </div>
              <br></br>
            </div>
          </Row>
        </Col>
      </Row>
        <br></br>
        <br></br>
        <br></br>
    </Container>
  );
};

export default Linechart;