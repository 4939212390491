import React, { useState, useEffect }  from 'react';



import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container, Row, Col, Button } from 'react-bootstrap';



import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import { TimeField } from '@mui/x-date-pickers/TimeField';


import QrReader from './Component/QrReader';
import { colors } from '@mui/material';







const ImageUploader = () => {


    const [qrData, setQrData] = useState('');

    //picker
    const [time, setTime] = useState(dayjs(new Date()));

    const [timeClick, setTimeClick] = useState();
    
    const [Delta, setDelta] = useState();


    // const [time, setTimeValue] = useState(
    //   new Date().toLocaleTimeString([], {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //   })
    // );






    const [temps, setTemps] = useState(
        new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
      );
    const [date, setDate] = useState(0);






  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  
  //   reader.onload = (e) => {
  //     const img = new Image();
  //     img.onload = async () => {
  //       try {
  //         const creationDate = await getCreationDate(file);
  //         console.log("Date de création de l'image :", creationDate);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };
  //     img.src = e.target.result;
  //   };
  
  //   reader.readAsDataURL(file);
  // };
  

  // const getCreationDate = (img) => {
  //   return new Promise((resolve, reject) => {
  //     const file = img.src;
  //     const reader = new FileReader();
      
  //     reader.onload = function (e) {
  //       const exif = EXIF.readFromBinaryFile(this.result);
  //       if (exif && exif.DateTimeOriginal) {
  //         resolve(exif.DateTimeOriginal);
  //       } else {
  //         reject("Date de création inconnue");
  //       }
  //     };
      
  //     reader.readAsArrayBuffer(file);
  //   });
  // };





  useEffect(() => {
    const interval = setInterval(() => {
      setTemps(
        new Date().toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
        );
      setDate(
        new Date().toLocaleDateString([], {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        );

    }, 100);
    return () => clearInterval(interval);
  }, []);



  

  const changeValue = (newValue) => {
    setTime(newValue);
    // const formattedTime = newValue.format('HH:mm:ss'); // Formatage de la date
  };

  


  const handleclick = () => {
    // const now = new Date(); // Obtenir l'instant présent
    // const mtn = dayjs(now);
    const mtn = dayjs(new Date());
    const formattedTime = mtn.format('HH:mm:ss'); // Formatage de la date
    setTimeClick(formattedTime);


    const delta = mtn.diff(time);
    // const formdelta = delta.format('HH:mm:ss');
    setDelta(delta)
  }

  return (
    <Container fluid>
      <Col className="text-center">

        <Row>
          <Col xs={0} lg={3}></Col>
          <Col className="text-center"  xs={12} lg={6}>
            <QrReader qrData={qrData} setQrData={setQrData}/>
            <p>Numéro de montre : {qrData}</p>
          </Col>
          <Col xs={0} lg={3}></Col>
        </Row>

        <br></br>
        <br></br>
        <br></br>






        <Row>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DemoContainer components={['TimeClock', 'TimeClock']}>
              <DemoItem label="Controlled clock"> */}
                {/* <TimeClock views={['hours', 'minutes', 'seconds']} value={time} onChange={(newValue) => changeValue(newValue)} /> */}

                {/* <TimeField
                  label="Heure de la montre"
                  defaultValue={time}
                  onChange={(newValue) => changeValue(newValue)}
                  format="HH:mm:ss"
                /> */}


                <Col>
                  <TimeClock views={['hours']} value={time} onChange={(newValue) => changeValue(newValue)}   ampm={false}/>
                  <p style={{ color: '#28A745' }}>{time.format('HH')} heures</p>
                </Col>
                <Col>
                  <TimeClock views={['minutes']} value={time} onChange={(newValue) => changeValue(newValue)} />
                  <p style={{ color: '#28A745' }}>{time.format('mm')} minutes</p>
                </Col>
                <Col>
                  <TimeClock views={['seconds']} value={time} onChange={(newValue) => changeValue(newValue)}/>
                  <p style={{ color: '#28A745' }}>{time.format('ss')} secondes</p>
                </Col>




              {/* </DemoItem>
            </DemoContainer> */}
          </LocalizationProvider>
        </Row>

        {/* <Row>
          <Col xs={0} lg={3}></Col>
          <Col className="text-center"  xs={12} lg={6}>
            <p>Heure cible:</p>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
                <text textAnchor="middle" x="125" y="80" fill="#FF9E40" fontFamily='Helvetica' fontSize="30" fontWeight="bold">{time.format('HH:mm:ss')}</text>
            </svg>
          </Col>
          <Col xs={0} lg={3}></Col>
        </Row> */}

        <Row>
          <Col className="text-center"  xs={6} lg={6}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
                  <text textAnchor="middle" x="125" y="20" fill="#FF9E40"  fontSize="20" fontWeight="bold">{date}</text>
                  <text textAnchor="middle" x="125" y="80" fill="#FF9E40" fontFamily='Helvetica' fontSize="50" fontWeight="bold">{temps}</text>
              </svg>
          </Col>
          <Col className="text-center"  xs={6} lg={6}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
              <text textAnchor="middle" x="125" y="20" fill="#28A745"  fontSize="20" fontWeight="bold">Heure de contrôle:</text>
              <text textAnchor="middle" x="125" y="80" fill="#28A745" fontFamily='Helvetica' fontSize="50" fontWeight="bold">{time.format('HH:mm:ss')}</text>
            </svg>
          </Col>
        </Row>
        

        {/* <Row>
          <Col className="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
                  <text textAnchor="middle" x="125" y="20" fill="#FF9E40"  fontSize="8" fontWeight="bold">{date}</text>
                  <text textAnchor="middle" x="125" y="80" fill="#FF9E40" fontFamily='Helvetica' fontSize="50" fontWeight="bold">{temps}</text>
              </svg>
          </Col>
        </Row> */}



        <Row>
          <Col xs={0} lg={3}></Col>
            <Col className="text-center"  xs={12} lg={6}>
            <Button variant="danger" size="lg" onClick={handleclick}> top !</Button>
          </Col>
          <Col xs={0} lg={3}></Col>
        </Row>
        <br></br>


        <Row>
          {Delta &&
          <Col xs={6} lg={6}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
              {/* <text textAnchor="middle" x="125" y="20" fill="#eb3434"  fontSize="8" fontWeight="bold">{date}</text> */}
              {/* <text textAnchor="middle" x="125" y="80" fill="#eb3434" fontFamily='Helvetica' fontSize="30" fontWeight="bold">Δt = {Delta} ms</text> */}
              <text textAnchor="middle" x="125" y="80" fill="#eb3434" fontFamily='Helvetica' fontSize="40" fontWeight="bold">Δt = {(Delta/1000).toFixed(3)} s</text>
            </svg>
          </Col>
          }
          <Col className="text-center"  xs={6} lg={6}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 100">
                {/* <text textAnchor="middle" x="125" y="20" fill="#eb3434"  fontSize="8" fontWeight="bold">{date}</text> */}
                <text textAnchor="middle" x="125" y="80" fill="#eb3434" fontFamily='Helvetica' fontSize="40" fontWeight="bold">{timeClick}</text>
            </svg>
          </Col>
        </Row>



        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>



      </Col>

    </Container>
  );
};

export default ImageUploader;
