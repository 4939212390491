import React, { useRef } from 'react';
import QrScanner from 'qr-scanner';
// Chargement du module de décodage de QR codes
QrScanner.WORKER_PATH = './qr-scanner-worker.min.js';

import { Container, Row, Col, Button } from 'react-bootstrap';

import QrFrame from '/img/icon-72x72.png'


const QrReader = ({ qrData, setQrData }) => { // Ajoutez les accolades pour déstructurer les arguments

  const videoRef = useRef(null);

  const startScanner = async () => {
    const scanner = new QrScanner(videoRef.current, (result) => {
      console.log(result); // Résultat du scan
      setQrData(result);
      scanner.stop(); // Arrêter la lecture une fois que le QR code est trouvé
    });
    scanner.start(); // Démarrer la lecture
  };

  return (
    <Col>
      <Row>
        <Button variant="danger" size="lg" onClick={startScanner}> scan</Button>
      </Row>
      <br></br>
      
      <Row>
        <div style={{ position: 'relative', width: '100%', height: '50vh' }}>
          {/* Video */}
          <video ref={videoRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></video>

          {/* SVG avec cadre */}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            {/* Rectangle de fond */}
            {/* <rect x="0" y="0" width="500" height="500" fill="none" stroke="#000" strokeWidth="2" /> */}

            {/* Cadre au centre pour le QR Code */}
            {/* <rect x="150" y="150" width="200" height="200" fill="none" stroke="#000" strokeWidth="2" /> */}

            {/* Croix au centre */}
            <line x1="150" y1="250" x2="350" y2="250" stroke="yellow" strokeWidth="2" />
            <line x1="250" y1="150" x2="250" y2="350" stroke="yellow" strokeWidth="2" />


            {/* Autres éléments de votre SVG */}
          </svg>
        </div>
      </Row>

      
    </Col>
  );
};

export default QrReader;
