import React, { useRef, useState, useEffect } from 'react';

import Alert from 'react-bootstrap/Alert';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

// import Chart from './apichart';

/* The following line can be included in your src/index.js or App.js file */
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';


// import Sponso from './Sponsor';
// import Story from './Story'

// import MapCam from './MapCamStory'

import Clock from "./montre";

import ImageUpload from './ImageUpload'; // Importation de loading depuis ImageUpload
import ImageUpload_bis from './ImageUpload_bis'; // Importation de loading depuis ImageUpload
import QrGen from './QrGenerator'; 

import Chart from './apichart'


import * as React from 'react';
import SimpleBottomNavigation from './nav'





export function App() {

  const [error404, setError404] = useState(false);


  
  const [ktskmh, setktskmh] = useState(1);


  const [selectedTab, setSelectedTab] = React.useState(0);
  
  
 
  const [timelaps, setTimelaps] = useState(6); 

  

  
  
  const changemonTimelabs = (timelaps) => {
    setTimelaps(timelaps);
  };

  

  const clearCacheAndReload = () => {
    if ('caches' in window) {
      // Supprime tous les caches existants
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    // Recharge la page
    window.location.reload(true);
  };





  
  
  return (
    <Container fluid>
      <br />
      {error404 &&
        <Alert variant="info" onClose={() => setError404(false)} dismissible>
          <Alert.Heading>Update... ⏳</Alert.Heading>
          <p>
          <br></br>
            Your App has been succesfully updated and is reloading
          <br></br>
          </p>
        </Alert>
      }
      
      {selectedTab === 0 ? (
          <ImageUpload/>
        ) : selectedTab === 1 ? (
          <QrGen/>
        ) : selectedTab === 2 ? (
          <ImageUpload_bis/>
        ) : selectedTab === 3 ? (
          <Chart/>
 
        ) : (
          <Clock montre={1}/>
        )
      }

        <SimpleBottomNavigation
        value={selectedTab}
        onChange={(event, newValue) => {
          setSelectedTab(newValue);
        }}
        />
    </Container>
  );
}
