import React, { useState, useEffect }  from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';



import QRCode from 'qrcode';







const QrGen = () => {

    const [qrCodeURL, setQRCodeURL] = useState(null);



    // const uniqueFilename = (nom) => {
    const uniqueFilename = () => {
        // const timestamp = new Date().toISOString().replace(/[-:]/g, '');
        const randomId = Math.random().toString(36).substring(2, 8).toUpperCase()+Math.random().toString(36).substring(2, 8).toUpperCase()+Math.random().toString(36).substring(2, 8).toUpperCase()+Math.random().toString(36).substring(2, 8).toUpperCase();
        // const uniqueName = `${nom}_${timestamp}_${randomId}.pdf`
        // const saveName = `${nom}_${timestamp}`  
        // return { uniqueName, randomId, saveName };
        return { randomId };
      };


      // Fonction pour générer le QR code
    const generateQRCode = async (text) => {
        try {
        const qrDataURL = await QRCode.toDataURL(text);
        setQRCodeURL(qrDataURL);
        return qrDataURL;
        } catch (error) {
        console.error('Erreur lors de la génération du QR code :', error);
        return null;
        }
    };
  


    const handleclick = () => {
        
        const { randomId } = uniqueFilename();
        // generateQRCode(id);
        generateQRCode(randomId);
        console.log('id')
        console.log(randomId)
      
    }


    const handleSaveImage = () => {
        const downloadLink = document.createElement('a');
        downloadLink.href = qrCodeURL;
        downloadLink.download = 'qrcode.png'; // Nom de fichier pour l'image
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };




  

  return (
    <Container fluid>
      <Col className="text-center">

        <Row>
          <Col xs={0} lg={3}></Col>
            <Col className="text-center"  xs={12} lg={6}>
            <Form.Select aria-label="Default select example">
              <optgroup label='Marque 1'>
                {/* <option>Open this select menu</option> */}
                <option value="1">Modèle 1</option>
                <option value="2">Modèle 2</option>
                <option value="3">Modèle 3</option>
              </optgroup>
              <optgroup label='Marque 2'>
                {/* <option>Open this select menu</option> */}
                <option value="4">Modèle 1</option>
                <option value="5">Modèle 2</option>
                <option value="6">Modèle 3</option>
              </optgroup>
              <optgroup label='Rolex'>
                <option value="9">Datejust</option>
                <option value="10">Submariner</option>
                <option value="11">GMT‑Master II</option>
                <option value="12">Day‑Date</option>
                <option value="13">Cosmograph Daytona</option>
                <option value="14">Oyster Perpetual</option>
                <option value="15">Yacht‑Master</option>
                <option value="16">Sea‑Dweller</option>
                <option value="17">Deepsea</option>
                <option value="18">Air‑King</option>
                <option value="19">Explorer</option>
                <option value="20">Lady‑Datejust</option>
                <option value="21">Sky‑Dweller</option>
                <option value="22">1908</option>
              </optgroup>
            </Form.Select>
          </Col>
          <Col xs={0} lg={3}></Col>
        </Row>

        <br></br>

        <Row>
          <Col xs={0} lg={3}></Col>
            <Col className="text-center"  xs={12} lg={6}>
            <Button variant="danger" size="lg" onClick={handleclick}> QrCode</Button>
          </Col>
          <Col xs={0} lg={3}></Col>
        </Row>

        <Row>
            <Col xs={0} lg={3}></Col>
            <Col className="text-center"  xs={12} lg={6}>
                {qrCodeURL && (
                    <img src={qrCodeURL} alt="QR Code" />
                )}
            </Col>
            <Col xs={0} lg={3}></Col>
        </Row>


        {qrCodeURL && (
        <Row>
          <Col xs={0} lg={3}></Col>
            <Col className="text-center"  xs={12} lg={6}>
            <Button variant="danger" size="lg" onClick={handleSaveImage}>Save</Button>
          </Col>
          <Col xs={0} lg={3}></Col>
        </Row>
        )}



        

    



        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>



      </Col>

    </Container>
  );
};

export default QrGen;
